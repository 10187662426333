
// Libraries
import * as React from 'react'

// Components
import Layout from '../../../components/layout'
import Seo from '../../../components/seo'
import RegisterForm from '../../../components/registerForm'
// import DuoSection from '../../../components/duoSection'

const RegisterNhsPage = () => (
	<Layout className="register-carer-nhs nav-blue-half no-footer register-flow">
		<Seo title="Register NHS Carer" />
		<RegisterForm process="nhs-carer"/>
	</Layout>
)

export default RegisterNhsPage
